@import '~bootstrap/scss/bootstrap.scss';

.swiper {
    width: 100%;
    height: 100%;
}

.product-quantity-container {
    height: 38px;
    display: flex;
    border: 1px solid #6BCB38;
    border-radius: 20px;
}

.product-quantity-container span {
    width: 33%;
}

.product-quantity-icon {
    color: #6BCB38;
    font-size: 27px;
    cursor: pointer;
}

.keyboard-disable {
    width: 1100px;
    height: 230px;
    position: absolute;
    opacity: 0.2;
    background-color: #959595;
}

.checkout-button {
    max-width: 300px;
    width: 100%;
    margin: auto;
    display: block;
    text-align: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    margin-top: 30px;
}

.continue-buying {
    background-color: #fff;
    color: #007bff;
    border: 1px solid #007bff;
}

.continue-payment {
    background-color: #007DC5;
    position: fixed;
    bottom: 2px;
    z-index: 99;
    max-width: unset;
    width: 100%;
    left: 0;
}

.key-board-container {
    box-shadow: 0px 0px 28px 0px #cdcdcd;
    padding-top: 45px;
    margin-bottom: -40px;
}

.dark-buttons-key-board {
    background-color: #ADB5BB !important;
}

.checkout-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.product-quantity-icon.first-icon {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.product-quantity-icon.last-icon {
    text-align: right;
    margin-right: 16px;
}

.product-quantity-icon.last-icon, .product-quantity {
    line-height: 38px;
}

.product-quantity {
    text-align: center;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dadedc!important;
    box-shadow: 1px 0px 4px 0px #dbdbdb;
    border-radius: 5px;
    padding: 20px;
    width: 80%;
}

.drug-image-container {
    height: 185px;
}

.swiper-slide img {
    display: block;
    max-width: 100%;
    max-height: 185px;
    width: auto;
    margin: auto;
    object-fit: cover;
}

span.drug-name {
    font-size: 16px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    margin-top: 20px;
    height: 48px;
}

.drug-price {
    font-size: 26px;
    color: #0077b8;
    font-weight: 600;
    margin: 20px 0px 10px;
}

.list-drug-title {
    padding: 10px 25px;
}

.list-drugs-mobile {
    display: none;
}

.last-message-checkout-link {
    margin-top: 20px;
}



.first-container {
    background-image: linear-gradient(31.53deg,#f4f8ff 3.69%,#dbe9ff 78.33%,#3f88ff 165.68%);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
//    background-color: #006FAF;
//    background-image: unset;
//    color: #fffFFF;
    height: 100vh;
    position: relative;
}

.btn-primary {
//    background-color: #ffffff;
//    color: #006FAF;
}

.invalid-token-container {
    position: absolute;
    bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.invalid-token {
    color: #0077b8;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.explanation-anamnese {
    font-size: 12px;
    color: #717171;
    text-align: center;
    margin-bottom: 25px;
}

.logo-container-key-board {
    bottom: 250px !important;
}

.logo-container {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    opacity: 0.8;
}

.powered-by-container {
    margin-right: 10px;
}

.clinic-farma {
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.powered-by {
    margin-left: 5px;
    color: #0077b8;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

.logo-container div img {
    margin: auto;
    display: block;
    max-height: 14px;
}

.powered-by .cnpj {
    font-size: 13px;
    color: #717171;
    margin-right: 6px;
    font-weight: initial;
}

.space, .logo-container div span {
    text-align: center;
    display: block;
}

.identifier-container-key-board {
    padding-bottom: 400px !important;
}

.identifier-container {
    width: 100%;
    max-width: 1000px;
    position: absolute;
    padding: 20px;
    padding-top: 0px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.login-container {
    box-shadow: 1px 1px 10px #0077b859;
    padding: 20px 40px;
    max-width: 700px;
    display: block;
    margin: auto;
}

.login-text {
    text-align: center;
    font-size: 20px;
    padding-bottom: 15px;
}

.login-form .inline-input {
    position: initial;
}


.login-form .inline-input input {
    text-align: center;
    font-size: 18px;
    width: 200px;
    margin: auto;
    padding: 0px;
}

.login-container .identifier-button {
    margin-bottom: 40px;
}

.checkbox-privacy-policy {
    display: flex;
    margin-bottom: 25px;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}

.form-check {
    margin-top: auto;
    margin-bottom: auto;
    display: inline-block;
}

.form-check input {
    width: 18px;
    height: 18px;
}

.terms-of-use-text {
    margin-top: auto;
    margin-left: 10px;
    margin-bottom: auto;
}

.terms-of-use-text a {
    font-weight: bold;
}

.identifier-container h2 {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    max-width: 600px;
    margin: 15px auto;
}

.identifier-container-phone {
    font-size: 16px;
    display: flex;
    margin: auto;
    align-items: baseline;
    justify-content: center;
}

.identifier-container-phone h2 {
    margin: 0;
    font-size: 16px;
    margin-right: 10px;
}

.identifier-input {
    max-width: 170px;
    display: block;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 0px;
}

.identifier-input input {
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    text-align: center;
}

.identifier-input input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #007bff38;
}

.identifier-button {
    max-width: 300px;
    width: 100%;
    margin: auto;
    display: block;
    background-color: #007DC5;
    border-radius: 19px;
}

.first-container-main {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 50px;
    max-width: 1200px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.key-board-container-cpf {
    position: absolute;
    width: 100%;
    max-width: 1100px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 10px;
    padding-top: 0;
}

.exchanging-messages {
    font-size: 16px;
    width: 100%;
    padding: 50px;
    max-width: 1200px;
    margin: auto;
    height: calc(100vh - 150px);
    overflow: auto;
}

.mobile-class .exchanging-messages {
    padding-top: 70px;
    padding-bottom: 30px;
}

.exchanging-messages::-webkit-scrollbar {
    width: 0;
}

.exchanging-messages-ended {
    font-size: 18px;
    width: 100%;
    padding: 50px;
    max-width: 1200px;
    margin: auto;
    height: 100vh;
    overflow: auto;
    padding-bottom: 65px !important;
}

.mobile-class .exchanging-messages-ended {
    padding-top: 70px;
}

.exchanging-messages-ended::-webkit-scrollbar {
    width: 0;
}

.exchanging-messages-ended .striped-product-container {
    margin-top: 20px;
}

.container-striper-container > .striped-product-container:nth-child(even) .product-container,
.container-striper-container > .striped-product-container:nth-child(even) .list-drug-title {
    background-color: #c1daff;
}

.striped-product-container {
    margin-bottom: 20px;
    border: 1px solid #005bec;
}

.striped-product-container .list-drug-title {
    background-color: #fff;
    padding: 10px 20px;
    font-size: 20px;
}

.product-container {
    background-color: #fff;
    margin-bottom: 0px;
}

.table th, .table td {
    font-size: 16px;
}

.message-pattern {
    padding: 20px 25px;
}

.message-type-assistant {
    background-color: #f7f7f8bf;
    border: 1px solid rgba(0,0,0,.1);
}

.message-type-assistant a {
    word-break: break-word;
}

.message-type-appointmentpoc {
    margin: 10px 0px;
    padding: 20px;
    background-color: #ffc863;
    font-size: 20px;
}

.typing {
    color: #666666;
}

.dropdown-container {
    padding-top: 50px;
}

.display-none {
    display: none !important;
}

.dropdown-container h2 {
    padding: 10px;
    padding-bottom: 50px;
    text-align: center;
}

.my-custom-dropdown {
    left: 50% !important;
    transform: translateX(-50%) !important;
    max-width: 1200px;
    width: calc(100% - 100px);
    border: 0px;
    box-shadow: 0px 0px 28px 0px #cdcdcd !important;
    padding-bottom: 0;
}

.my-custom-dropdown input {
    width: calc(100% - 32px)!important;
    font-size: 18px;
}

.my-custom-dropdown ul {
    max-height: 350px;
    overflow: auto;
    margin-bottom: 0;
}

.my-custom-dropdown ul a {
    text-transform: capitalize;
    border-bottom: 1px solid #007bff26;
    font-size: 18px;
    padding: 0.5rem 1.5rem;
}

.chat-form {
    bottom: 25px;
    position: relative;
}

.chat-form button {
    padding: 0px;
}

.icon-enviar {
    width: 35px;
    height: auto;
}

.balto-search-container {
    position: relative;
}

.balto-searchbox {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.balto-searchbox {
    width: 100%;
}

.balto-searchbox input {
    resize: none;
    border: 0px;
    box-shadow: 0px 0px 28px 0px #cdcdcd !important;
    padding-left: 25px;
    padding-right: 40px;
    background-color: #fff;
    height: 44px !important;
    padding-bottom: 11px;
    padding-top: 11px;
    line-height: 23px;
    position: absolute;
    width: 100%;
}

.balto-hits-container {
    display: flex;
    width: 100%;
    overflow-x: auto;
    position: relative;
    top: -20px;
    white-space: nowrap;
}

.search-button, .show-basket {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    border: 1px solid #005bec;
    max-width: 350px;
    margin-right: 10px;
    cursor: pointer;
}

.show-basket {
    background-color: #005bec;
    color: #fff;
}

.balto-hits-container li span {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: lowercase;
}

.balto-hits-container li span:first-letter {
    text-transform: uppercase;
}

.balto-hits-container::-webkit-scrollbar {
    display: none;
}

.balto-search-container ol {
    display: flex;
    padding-left: 0px;
}

.balto-search-container li::marker {
    content: '';
}

.message-type-condition {
    padding-bottom: 10px;
}

.condition-buttons-container {
    display: flex;
    width: 100%;
}

.condition-buttons-container ol {
    padding-left: 0px;
    margin-bottom: 0px;
}

.condition-buttons-container li::marker {
    content: '';
}

.condition-buttons-container li {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    border: 1px solid #005bec;
    margin-right: 10px;
    cursor: pointer;
    float: left;
    margin-bottom: 10px;
}

.background-blue {
    background-color: #c9daf8ff;
}

.show-symptoms {
    padding: 0px;
    margin: 15px 0px;
    cursor: pointer;
}

.show-symptoms li {
    background-color: #005bec;
    color: #fff;
    padding: 5px 10px;
    text-align: center;
    font-size: 18px;
    border-radius: 5px;
    font-weight: bold;
}

.condition-buttons-container li.checked-condition {
    background-color: #005bec;
    color: #fff;
}

.basket-container {
    background-color: #fff;
    margin-top: 20px;
}

.diagnosis-text-container {
    padding: 12px;
    font-size: 18px;
}
.diagnosis-text {
    font-weight: bold;
}

.product-price {
    min-width: 86px;
}

.product-margin {
    font-size: 14px;
    color: #005bec;
}

.basket-designation {
    font-weight: bold;
}

.table thead th, .table th, .table td {
    vertical-align: middle;
}

.hide-product {
    display: none;
}

.new-chat {
    position: fixed;
    right: 0;
    margin: 12px;
}

.inline-textarea {
    resize: none;
    border: 0px;
    box-shadow: 0px 0px 28px 0px #cdcdcd !important;
    padding-left: 25px;
    padding-right: 40px;
    background-color: #fff;
    height: 44px !important;
    padding-bottom: 11px;
    padding-top: 11px;
    line-height: 23px;
    position: absolute;
}

.inline-textarea::-webkit-scrollbar {
    background-color: transparent;
}

.inline-input {
    position: absolute;
    width: 100%;
}

.inline-input input {
    padding: 1.375rem 0.75rem;
    border: 0px;
    box-shadow: 0px 0px 28px 0px #cdcdcd !important;
    padding-left: 25px;
    padding-right: 60px;
}

.submit-form {
    position: absolute;
    right: 0;
    margin-top: 3.5px;
    margin-right: 4px;
}

.submit-form.input-disable {
    background-color: #d3d3d3;
    border-color: #d3d3d3;
}

.ended-chat-container {
    position: absolute;
    max-width: 700px;
    padding: 25px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    box-shadow: 0px 0px 28px 0px #cdcdcd;
    background-color: #fff;
}

.search-condition {
    position: absolute;
    bottom: -30px;
    right: 55px;
    color: #005becff;
    text-decoration-line: underline;
    cursor: pointer;
}

.search-condition-page {
    padding: 25px;
}

.condition-searched-input {
    position: relative;
}

.condition-searched-input {
    font-size: 18px;
}

.diagnosis-list {
    float: left;
    max-width: 100%;
}

.diagnosis-option {
    border: 1px solid #d5d5d5;
    padding: 5px 20px;
    margin: 10px 0px;
    color: #0077b8;
    background-color: #fff;
    font-size: 18px;
    box-shadow: 3px 3px 5px #d5d5d5;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    float: left;
    margin-right: 10px;
}

.combo-container {
    padding: 15px;
}

.combo-image {
    display: block;
    max-width: 100%;
    max-height: 185px;
    width: auto;
    margin: auto;
    object-fit: cover;
}

.combo-text-inntroduction {
    font-size: 17px;
    margin-bottom: 20px;
    font-weight: bold;
}

.combo-products {
    font-size: 16px;
    margin-bottom: 5px;
}

.combo-price {
    font-size: 26px;
    color: #0077b8;
    font-weight: 600;
    margin: 20px 0px 10px;
    text-align: center;
}

.explanation-container {
    background-color: #fff4d2d1;
    padding: 5px;
    margin-bottom: 20px;
}

.combo-products-explanation {
    margin-bottom: 5px;
    font-size: 18px;
}

@media only screen and (max-width: 675px) {
    .identifier-button, .identifier-input {
        max-width: 675px;
    }

    .first-container-main {
        padding: 30px 10px;
    }

    .exchanging-messages {
        padding: 10px;
        height: calc(100vh - 100px);
    }

    .exchanging-messages-ended {
        padding: 10px;
        height: 100vh;
    }

    .my-custom-dropdown {
        width: calc(100% - 20px);
    }

    .list-drugs-mobile {
        display: block;
    }

    .list-drugs-web {
        display: none;
    }

    .balto-hits-container li {
        max-width: 250px;
    }
}